<template>
    <div class="dingzhi-container">
        <div class="header-container">
            <div class="header-content">
                <div class="header-left">
                    <router-link to="/">
                        <div class="header-left"><img src="../../assets/svg/logo.svg" alt=""></div>
                    </router-link>
                </div>
                <div class="header-right">
                    <div v-for="(item, index) in menuList"
                         :to="{path: item.url}"
                         :key="index"
                         class="menu-item"
                         @click="changeTab(item)"
                         :class="{'active': item.value == activeValue}"> {{item.name}}</div>
                </div>
            </div>
        </div>
        <div class="dingzhi-content">
            <div class=dingzhi-left>
                <div>
                    <img :src="imgUrl" alt="">
                    <p class="baozhuang-title" v-if="baozhuang1.packageType==1">PE重包装袋(FFS袋)</p>
                    <p class="baozhuang-title" v-if="baozhuang1.packageType==2">PE阀口袋</p>
                </div>
            </div>
            <div white-space="pre" v-if="activeValue==1" class=dingzhi-right>
                <h3 class="right-title">联系方式</h3>
                <div>
                    <div class="input-item">
                        <span class="title">公司</span>
                        <el-input size="large" class="input" v-model="baozhuang1.company" placeholder="请输入公司"></el-input>
                    </div>
                    <div class="input-item">
                        <span class="title">姓名</span>
                        <el-input size="large" class="input" v-model="baozhuang1.userName" placeholder="请输入姓名"></el-input>
                    </div>
                    <div class="input-item">
                        <span class="title">手机</span>
                        <el-input size="large" class="input" v-model="baozhuang1.phone" placeholder="请输入手机"></el-input>
                    </div>
                    <div class="input-item">
                        <span class="title">邮箱</span>
                        <el-input size="large" class="input" v-model="baozhuang1.email" placeholder="请输入邮箱"></el-input>
                    </div>
                    <div class="input-item">
                        <span class="title">微信</span>
                        <el-input size="large" class="input" v-model="baozhuang1.wxNumber" placeholder="请输入微信"></el-input>
                    </div>
                </div>
            </div>
            <!--PE重包装带-->
            <template  v-if="baozhuang1.packageType == 1">
                <div v-if="activeValue==2" class=dingzhi-right>
                    <h3 class="right-title">使用范围</h3>
                    <div>
                        <p class="p-title">您需要包装的产品是什么？</p>
                        <div class="input-item">
                            <el-input size="large" class="input" v-model="baozhuang1.prodName" placeholder="请输入您所需要包装的产品"></el-input>
                        </div>
                        <p class="p-title">您的产品物料性质是什么？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.prodCategory" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in baozhuangdai.caiLiaoXingZhi"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                        <p class="p-title">您的产品应用领域是？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.applicationRange" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in baozhuangdai.yingYongLingYu"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.name">
                                    <span style="float: left">{{item.name}}</span>
                                    <span style="float: right">{{item.remark}}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <p class="p-title">您所需要包装的产品重量为多少？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.weight" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in baozhuangdai.chanPinZhongLiang"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div v-if="activeValue==3" class=dingzhi-right>
                    <h3 class="right-title">选择规格</h3>
                    <div>
                        <p class="p-title">您需要的包装尺寸是多少？</p>
                    <!--    <div class="input-item">
                            <span class="title">长</span>
                            <el-input size="large" class="input" v-model="baozhuang1.length"  @change="getPrice()" placeholder="请输入长"></el-input>
                            <span class="danwei">cm</span>
                        </div>-->

                        <div class="slid-content">
                            <div class="slid-item">
                                <div class="left-slid">长</div>
                                <div class="right-slid">
                                    <el-slider v-model="baozhuang1.length" @change="getPrice()" :min="30" :max="85" :format-tooltip="formatTooltip"></el-slider>
                                </div>
                                <div class="show-value">{{baozhuang1.length}}cm</div>
                            </div>
                            <div class="slid-item">
                                <div class="left-slid">宽</div>
                                <div class="right-slid">
                                    <el-slider v-model="baozhuang1.width"  @change="getPrice()" :min="30" :max="70" :format-tooltip="formatTooltip"></el-slider>
                                </div>
                                <div class="show-value">{{baozhuang1.width}}cm</div>
                            </div>
                        </div>
                        <p class="p-title">您需要包装的厚度是多少？(1丝=0.01mm)</p>
                        <div class="slid-content">
                            <div class="slid-item">
                                <div class="right-slid">
                                    <el-slider v-model="baozhuang1.thickness"  @change="getPrice()" :min="10" :max="24" :format-tooltip="formatTooltipHou"></el-slider>
                                </div>
                                <div class="show-value">{{baozhuang1.thickness}}丝</div>
                            </div>
                        </div>
                        <img class="fenge-content" src="../../assets/fenge.png" alt="">

                        <p class="p-title">你需要什么样的膜面？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.surfaceName" size="large"
                                       @change="moMianChange( baozhuangdai.moMian)"
                                       class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in baozhuangdai.moMian"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.name">
                                    <span style="float: left">{{item.name}}</span>
                                    <span style="float: right">{{item.remark}}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <p class="p-title">您额外的膜面配方要求？(多选)</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.surfaceConfig"
                                       @change="getPrice()"
                                       size="large" multiple class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in baozhuangdai.eWaiMoMian"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.name">
                                    <span style="float: left">{{item.name}}</span>
                                    <span style="float: right">{{item.remark}}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <p class="p-title">是否在袋身上抽边?</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.choubian" @change="moMianChange(baozhuangdai.moMian)" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in shiFouList"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <!--如果选择在抽身上抽边是显示-->
                        <template v-if="baozhuang1.choubian == 1">
                            <p class="p-title">您袋子的单边抽边尺寸？</p>
                            <div class="slid-content">
                                <div class="slid-item">
                                    <div class="right-slid">
                                        <el-slider v-model="baozhuang1.choubianSize" :max="15" @change="moMianChange(baozhuangdai.moMian)" :format-tooltip="formatTooltip"></el-slider>
                                    </div>
                                    <div class="show-value">{{baozhuang1.choubianSize}}cm</div>

                                </div>
                            </div>
                        </template>

                        <p class="p-title">是否需要在膜面上添加防滑条纹？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.striped"
                                       size="large"
                                       class="input flex1"
                                       @change="moMianChange(baozhuangdai.moMian)"
                                       placeholder="请选择">
                                <el-option
                                        label="是"
                                        :value="'1'">
                                    <span style="float: left">是</span>
                                </el-option>
                                <el-option
                                        label="否"
                                        :value="'0'">
                                <span>
                                    否
                               <!--  <el-tooltip class="item" effect="dark" content="压花条纹模具限制，袋身宽度至少30cm以上" placement="right">
                                     <i class="el-icon-question orange"></i>
                                 </el-tooltip>-->
                                </span>
                                </el-option>
                            </el-select>
                        </div>
                        <p class="p-title">是否需要产品具有透气性？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.breathable" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in shiFouList"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <template v-if="baozhuang1.breathable == 1">
                            <p class="p-title">选择袋子的透气方式？</p>
                            <div class="input-img" v-if="baozhuang1.breathableType">
                                <img class="wp100" v-if="baozhuang1.breathableType == '袋身扎排气孔'" src="../../static/dingZhiNew/fuJia/daishenzhapaiqikong.jpg" alt="">
                               <div v-if="baozhuang1.breathableType == '加装单向排气阀'" >
                                   <p style="text-align: center">图一为内部部分</p>
                                   <img class="wp100" src="../../static/dingZhiNew/fuJia/danxiangpaiqifa-nei.jpg" alt="">
                                   <p style="text-align: center">图二为外部部分</p>
                                   <img class="wp100" v-if="baozhuang1.breathableType == '加装单向排气阀'" src="../../static/dingZhiNew/fuJia/danxiangpaiqifa-wai.jpg" alt="">
                               </div>
                                <div v-if="baozhuang1.breathableType == '袋子热封处增加Y型角封'">
                                    <p style="text-align: center">图一为灌装物料前效果</p>
                                    <img class="wp100" src="../../static/dingZhiNew/fuJia/guanzhuangwuliao-qian.jpg" alt="">
                                    <p style="text-align: center">图二为灌装物料后撑起效果</p>
                                    <img class="wp100" src="../../static/dingZhiNew/fuJia/guanzhuangwuliao-hou.jpg" alt="">
                                </div>
                            </div>
                            <div class="input-item">
                                <el-select v-model="baozhuang1.breathableType" size="large" class="input flex1" placeholder="请选择">
                                    <el-option
                                            v-for="(item,index) in baozhuangdai.touQiFangShi"
                                            :key="index"
                                            :label="item.name"
                                            :value="item.name">
                                        <span style="float: left">{{item.name}}</span>
                                        <span style="float: right">{{item.remark}}</span>
                                    </el-option>
                                </el-select>
                            </div>
                        </template>

                        <template v-if="baozhuang1.breathable == 1 && baozhuang1.breathableType == '加装单向排气阀' && baozhuang1.thickness < 14">
                            <div style="margin-bottom: 15px;color: orange;font-size: 18px;">加装排气阀膜面厚度至少14丝,当前厚度:{{baozhuang1.thickness}}丝,请重新选择</div>
                        </template>
                        <template v-if="baozhuang1.breathable == 1 && baozhuang1.breathableType == '袋子热封处增加Y型角封'">
                            <p class="p-title">选择Y型角封位置？(多选)</p>
                            <div class="input-item">
                                <el-select multiple v-model="baozhuang1.fengPosition" size="large" class="input flex1" placeholder="请选择">
                                    <el-option
                                            v-for="(item,index) in baozhuangdai.YFengKouWeiZhi"
                                            :key="index"
                                            :label="item.name"
                                            :value="item.name">
                                    </el-option>
                                </el-select>
                            </div>
                        </template>
                        <p class="p-title">是否在封口增加圆点角封？</p>
                        <div style="font-size: 14px;margin-top: -10px;margin-bottom: 10px;color: #999">温馨提示：1.若袋子宽度大于灌装机口径，可以用圆点角封减小口径。2.自动灌装设备和自动封口设备可通过圆点角封校准。</div>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.pointFeng" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in shiFouList"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <template v-if="baozhuang1.pointFeng == 1">
                            <p class="p-title">请选择您的角封类型?</p>
                            <div class="input-img" v-if="baozhuang1.pointFengType">
                                <img class="wp100" v-if="baozhuang1.pointFengType == '圆点角封合拢（袋口更窄）'" src="../../static/dingZhiNew/fuJia/yuandianjiaofeng1.jpg" alt="">
                                <img class="wp100" v-if="baozhuang1.pointFengType == '圆点角封分开（袋口相对宽些）'" src="../../static/dingZhiNew/fuJia/yuandianjiaofeng2.jpg" alt="">
                            </div>
                            <div class="input-item">
                                <el-select v-model="baozhuang1.pointFengType" size="large" class="input flex1" placeholder="请选择">
                                    <el-option
                                            v-for="(item,index) in baozhuangdai.fengKouZengJiaYuanDianJiaoFeng"
                                            :key="index"
                                            :label="item.name"
                                            :value="item.name">
                                    </el-option>
                                </el-select>
                            </div>
                        </template>

                    </div>
                </div>
            </template>
            <!--PE阀口袋-->
            <template  v-if="baozhuang1.packageType == 2">
                <div v-if="activeValue==2" class=dingzhi-right>
                    <h3 class="right-title">使用范围</h3>
                    <div>
                        <p class="p-title">您需要包装的产品是什么？</p>
                        <div class="input-item">
                            <el-input size="large" class="input" v-model="baozhuang1.prodName" placeholder="请输入您所需要包装的产品"></el-input>
                        </div>
                        <p class="p-title">您的产品物料性质是什么？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.prodCategory" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in fakoudai.caiLiaoXingZhi"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                        <p class="p-title">您的产品应用领域是？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.applicationRange" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in fakoudai.yingYongLingYu"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.name">
                                    <span style="float: left">{{item.name}}</span>
                                    <span style="float: left">{{item.remark}}</span>
                                </el-option>
                            </el-select>
                        </div>
                        <p class="p-title">您所需要包装的产品重量为多少？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.weight" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in fakoudai.chanPinZhongLiang"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <div v-if="activeValue==3" class=dingzhi-right>
                    <h3 class="right-title">选择规格</h3>
                    <div>
                        <p class="p-title">您需要的包装尺寸是多少？</p>
                        <div class="slid-content">
                            <div class="slid-item">
                                <div class="left-slid">长</div>
                                <div class="right-slid">
                                    <el-slider v-model="baozhuang1.length" @change="getPrice()" :min="30" :max="85" :format-tooltip="formatTooltip"></el-slider>
                                </div>
                                <div class="show-value">{{baozhuang1.length}}cm</div>
                            </div>
                            <div class="slid-item">
                                <div class="left-slid">宽</div>
                                <div class="right-slid">
                                    <el-slider v-model="baozhuang1.width" @change="getPrice()" :min="30" :max="70" :format-tooltip="formatTooltip"></el-slider>
                                </div>
                                <div class="show-value">{{baozhuang1.width}}cm</div>
                            </div>
                            <div class="slid-item">
                                <div class="left-slid">底</div>
                                <div class="right-slid">
                                    <el-slider v-model="baozhuang1.bottom" @change="getPrice()" :max="18" :format-tooltip="formatTooltip"></el-slider>
                                </div>
                                <div class="show-value">{{baozhuang1.bottom}}cm</div>
                            </div>
                        </div>
                        <p class="p-title mt25 mb10">您需要包装的厚度是多少？(1丝=0.01mm)</p>
                        <div class="slid-content">
                            <div class="slid-item">
                                <div class="right-slid">
                                    <el-slider v-model="baozhuang1.thickness" @change="getPrice()" :min="10" :max="24" :format-tooltip="formatTooltipHou"></el-slider>
                                </div>
                                <div class="show-value">{{baozhuang1.thickness}}丝</div>
                            </div>
                        </div>
                        <img class="fenge-content" src="../../assets/fenge.png" alt="">

                        <p class="p-title">你需要什么样的膜面？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.surfaceName" size="large"
                                       @change="moMianChange(fakoudai.moMian)"
                                       class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in fakoudai.moMian"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </div>
                        <p class="p-title">是否需要在膜面上添加防滑条纹？</p>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.striped"
                                       @change="moMianChange(fakoudai.moMian)"
                                       size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in shiFouList"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>

                        <p class="p-title">是否需要产品具有透气性？</p>
                        <div class="input-img">
                            <img class="wp100" src="../../static/dingZhiNew/fuJia/daishenzhapaiqikong.jpg" alt="">
                        </div>
                        <div class="input-item">
                            <el-select v-model="baozhuang1.breathable" size="large" class="input flex1" placeholder="请选择">
                                <el-option
                                        v-for="(item,index) in shiFouList"
                                        :key="index"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </template>
            <div v-if="activeValue==4" class=dingzhi-right>
                <h3 class="right-title">询价</h3>
                <div>
                    <p class="p-title">您大概需要多少数量的袋子？</p>
                    <div class="input-item">
                        <el-select v-model="baozhuang1.packageNumber"
                                   @change="getPrice()"
                                   size="large" class="input flex1"
                                   placeholder="请选择">
                            <el-option
                                    v-for="(item,index) in shuLiangList"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <p class="p-title">获取更低报价与技术方面配方定制请联系？</p>
                    <div class="phone-content">
                        <!--<img src="../../assets/svg/dingzhi_icon_dianhua.svg" alt="">-->
                        <!--<span class="phone">131-2365-4789</span>-->
                        <div class="getLianxi" @click="xunJia()">获取联系方式</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-container">
            <div class="footer-content">
                <div class="left">
                    <span v-if="!baozhuang1.unitPrice">选择规格后可查看预估报价</span>
                    <span class="money-text" v-if="baozhuang1.unitPrice">￥{{baozhuang1.unitPrice}} 元/条</span>
                </div>
                <div class="right">
                    <div class="btn-item prv" @click="pev()" v-if="activeValue>1">上一步</div>
                    <div class="btn-item next" @click="next()" v-if="activeValue<4">下一步</div>
                    <div class="btn-item next" @click="xunJia()" v-if="activeValue==4">询价</div>
                </div>
            </div>
        </div>
        <el-dialog
                title=""
                :modal="isModal"
                :visible.sync="dialogVisible"
                width="550px">

            <div class="modal-content">
                <h1>￥{{baozhuang1.unitPrice}} <span class="unit">元/条</span>  </h1>
                <div class="title-text">（您的预估报价）</div>
                <div class="text-content">
                    <div class="left-item">
                        <div class="title">手机号</div>
                        <div class="left-item-text">
                            <img src="../../assets/svg/dingzhi_icon_shouji.svg" alt="手机">
                            <span>152-0577-8951</span>
                        </div>
                        <div class="title">发送邮件</div>
                        <div class="left-item-text">
                            <img src="../../assets/svg/dingzhi_icon_youxiang.svg" alt="邮箱">
                            <span>958424773@qq.com</span>
                        </div>
                    </div>
                    <div class="right-item">
                        <img src="../../assets/image/weixin1.jpeg" alt="">
                        <p class="erweima-text">添加我们的微信</p>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    /*阀口袋 - 有防滑膜*/
    // 定制颜色膜
    import dingzhiyansemofakoudai from '../../static/dingZhiNew/fakoudai/dingzhiyansemofakoudai.min.png';
    // 黑白膜
    import heibaimofakoudai from '../../static/dingZhiNew/fakoudai/heibaimofakoudai.min.png';
    // 亮光膜
    import liangguangmofakoudai from '../../static/dingZhiNew/fakoudai/liangguangmofakoudai.min.png';
   // 透明膜
    import toumingmofakoudai from '../../static/dingZhiNew/fakoudai/toumingmofakoudai.min.png';
   // 哑光膜
    import yaguangmofakoudai from '../../static/dingZhiNew/fakoudai/yaguangmofakoudai.min.png';
    /*阀口袋 - 无防滑膜*/
    // 定制颜色膜-无
    import dingzhiyansemofakoudaiWu from '../../static/dingZhiNew/fakoudai/wufanghuatiaowen/dingzhiyansemofakoudaiWu.min.png';
    // 黑白膜-无
    import hebaimofakoudaiWu from '../../static/dingZhiNew/fakoudai/wufanghuatiaowen/hebaimofakoudaiWu.min.png';
    // 亮光膜-无
    import liangguangmofakoudaiWu from '../../static/dingZhiNew/fakoudai/wufanghuatiaowen/liangguangmofakoudaiWu.min.png';
    // 透明膜-无
    import toumingmofakoudaiWu from '../../static/dingZhiNew/fakoudai/wufanghuatiaowen/toumingmofakoudaiWu.min.png';
    // 哑光膜-无
    import yaguangmofakoudaiWu from '../../static/dingZhiNew/fakoudai/wufanghuatiaowen/yaguangmofakoudaiWu.min.png';

    /*重包装袋- 抽边 - 有防滑膜*/
    // 定制颜色膜抽边带
    import zhongdingzhiyansemochoubiandai from '../../static/dingZhiNew/zhongbaozhuangdai/dingzhiyansemochoubiandai.min.png';
    // 黑白膜抽边带
    import zhongheibaimochoubiandai from '../../static/dingZhiNew/zhongbaozhuangdai/heibaimochoubiandai.min.png';
    // 亮光膜抽边带
    import zhongliangguangmochoubiandai from '../../static/dingZhiNew/zhongbaozhuangdai/liangguangmochoubiandai.min.png';
    // 透明膜抽边带
    import zhongtoumingmochoubiandai from '../../static/dingZhiNew/zhongbaozhuangdai/toumingmochoubiandai.min.png';
    // 哑光膜边带
    import zhongyaguangmochoubiandai from '../../static/dingZhiNew/zhongbaozhuangdai/yaguangmochoubiandai.min.png';

    /*重包装袋 -抽边- 无防滑膜*/
    // 定制颜色抽边带-无
    import zhongdingzhiyansechoubiandaiWu from '../../static/dingZhiNew/zhongbaozhuangdai/wufanghuatiaowen/dingzhiyansechoubiandaiWu.min.png';
    // 黑白膜抽边带-无
    import zhongheibaimochoubiandaiWu from '../../static/dingZhiNew/zhongbaozhuangdai/wufanghuatiaowen/heibaimochoubiandaiWu.min.png';
    // 亮光膜抽边带-无
    import zhongliangguangmochoubiandaiWu from '../../static/dingZhiNew/zhongbaozhuangdai/wufanghuatiaowen/liangguangmochoubiandaiWu.min.png';
    // 透明膜抽边带-无
    import zhongtoumingmochoubiandaiWu from '../../static/dingZhiNew/zhongbaozhuangdai/wufanghuatiaowen/toumingmochoubiandaiWu.min.png';
    // 哑光膜抽边带-无
    import zhongyaguangchoubiandaiWu from '../../static/dingZhiNew/zhongbaozhuangdai/wufanghuatiaowen/yaguangchoubiandaiWu.min.png';


    /*重包装袋- 无抽边 - 有防滑膜*/
    // 定制颜色膜无抽边带
    import dingzhiyansemozhenxingdai from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/dingzhiyansemozhenxingdai.min.png';
    // 黑白膜抽无边带
    import heibaimozhenxingdai from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/heibaimozhenxingdai.min.png';
    // 亮光膜抽无边带
    import liangguangmozhenxingdai from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/liangguangmozhenxingdai.min.png';
    // 透明膜抽无边带
    import toumingmozhenxing from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/toumingmozhenxing.min.png';
    // 哑光膜无抽边带
    import yaguangmozhenxingdai from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/yaguangmozhenxingdai.min.png';

    /*重包装袋 -无抽边- 无防滑膜*/
    // 定制颜色无抽边带-无
    import dingzhiyansemozhenxingdaiWu from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/wufanghuatiaowen/dingzhiyansemozhenxingdaiWu.min.png';
    // 黑白膜无抽边带-无
    import heibaimozhenxingdaiWu from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/wufanghuatiaowen/heibaimozhenxingdaiWu.min.png';
    // 亮光膜无抽边带-无
    import liangguangzhenxingdaiWu from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/wufanghuatiaowen/liangguangzhenxingdaiWu.min.png';
    // 透明膜无抽边带-无
    import toumingmozhenxingWu from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/wufanghuatiaowen/toumingmozhenxingWu.min.png';
    // 哑光膜无抽边带-无
    import yaguangzhenxingdaiWu from '../../static/dingZhiNew/wuchoubianzhongbaozhuangdai/wufanghuatiaowen/yaguangzhenxingdaiWu.min.png';


    export default {
        name: "index",
        data() {
            return {
                imgUrlObj: {
                    // 重包装带
                    //  x1-x2-x3:  x1:膜面类型  x2：是否有防滑膜（1，是  0，否） x3:是否抽边（1，是  0，否）
                    1: {
                        '1-1-1': zhongliangguangmochoubiandai, // 亮光膜-有防滑膜-抽边
                        '2-1-1': zhongyaguangmochoubiandai, // 乳白哑光膜-有防滑膜-抽边
                        '3-1-1': zhongheibaimochoubiandai, // 外白内黑膜-有防滑膜-抽边
                        '4-1-1': zhongtoumingmochoubiandai, // 透明膜-有防滑膜-抽边
                        '5-1-1': zhongdingzhiyansemochoubiandai, // 定制颜色膜-有防滑膜-抽边

                        '1-1-0': liangguangmozhenxingdai, // 亮光膜-有防滑膜-无抽边
                        '2-1-0': yaguangmozhenxingdai, // 乳白哑光膜-有防滑膜-无抽边
                        '3-1-0': heibaimozhenxingdai, // 外白内黑膜-有防滑膜-无抽边
                        '4-1-0': toumingmozhenxing, // 透明膜-有防滑膜-无抽边
                        '5-1-0': dingzhiyansemozhenxingdai, // 定制颜色膜-有防滑膜-无抽边

                        '1-0-1': zhongliangguangmochoubiandaiWu, // 亮光膜-无防滑膜-抽边
                        '2-0-1': zhongyaguangchoubiandaiWu, // 乳白哑光膜-无防滑膜-抽边
                        '3-0-1': zhongheibaimochoubiandaiWu, // 外白内黑膜-无防滑膜-抽边
                        '4-0-1': zhongtoumingmochoubiandaiWu, // 透明膜-无防滑膜-抽边
                        '5-0-1': zhongdingzhiyansechoubiandaiWu, // 定制颜色膜-无防滑膜-抽边

                        '1-0-0': liangguangzhenxingdaiWu, // 亮光膜-无防滑膜-无抽边
                        '2-0-0': yaguangzhenxingdaiWu, // 乳白哑光膜-无防滑膜-无抽边
                        '3-0-0': heibaimozhenxingdaiWu, // 外白内黑膜-无防滑膜-无抽边
                        '4-0-0': toumingmozhenxingWu, // 透明膜-无防滑膜-无抽边
                        '5-0-0': dingzhiyansemozhenxingdaiWu, // 定制颜色膜-无防滑膜-无抽边
                    },
                    // 阀口袋
                    2: {
                        '1-1': liangguangmofakoudai, // 亮光膜-有防滑膜
                        '2-1': yaguangmofakoudai, // 乳白哑光膜-有防滑膜
                        '3-1': heibaimofakoudai, // 外白内黑膜-有防滑膜
                        '4-1': toumingmofakoudai, // 透明膜-有防滑膜
                        '5-1': dingzhiyansemofakoudai, // 定制颜色膜-有防滑膜
                        '1-0': liangguangmofakoudaiWu, // 亮光膜-无防滑膜
                        '2-0': yaguangmofakoudaiWu, // 乳白哑光膜-无防滑膜
                        '3-0': hebaimofakoudaiWu, // 外白内黑膜-无防滑膜
                        '4-0': toumingmofakoudaiWu, // 透明膜-无防滑膜
                        '5-0': dingzhiyansemofakoudaiWu, // 定制颜色膜-无防滑膜
                    }
                },
                dialogVisible: false,
                isModal: true,
                menuList: [
                    {name: '1.联系方式', value: 1},
                    {name: '2.使用范围', value: 2},
                    {name: '3.选择规格', value: 3},
                    {name: '4.询价', value: 4},
                ],
                activeValue: 1,
                imgUrl: zhongliangguangmochoubiandai,
                defaultImg: {
                    1: liangguangmozhenxingdai,
                    2: liangguangmofakoudai
                },
                baozhuang1: {
                    packageType: 1, // 包装类型 1，PE重包装带 2，PE阀口袋
                    company: '', // 客户公司
                    userName: '', // 客户姓名
                    phone: '', // 客户电话
                    email: '', // 客户电话
                    wxNumber: '', // 客户微信
                    prodName: '', // 包装产品名称
                    prodCategory: '', // 包装产品性质
                    applicationRange: '', // 包装产品应用范围
                    weight: '5kg', // 包装产品重量
                    length: 55, // 包装袋长度
                    width: 70, // 包装袋宽度
                    bottom: 12, // 包装袋底部
                    thickness: 10, // 包装袋厚度
                    surfaceName: '', // 包装袋膜面类型
                    surfaceConfig: [], // 您额外的膜面配方要求
                    choubian: '0', // 是否在袋身上抽边,
                    choubianSize: 0, // 抽边尺寸

                    striped: '0', // 是否需要在膜面上添加防滑条纹
                    breathable: '0', // 是否需要产品具有透气性
                    breathableType: '', // 选择袋子的透气方式

                    fengPosition: [], // 选择Y型角封位置
                    pointFeng: '0', // 是否在封口增加圆点角封
                    pointFengType: '', // 角封类型
                    packageNumber: 3000, // //需要的包装袋数量
                    unitPrice: 0 // 单价
                },
                shiFouList: [
                    {name: '是', value: '1'},
                    {name: '否', value: '0'},
                ],
                shuLiangList: [
                    {name: '3000条以下', value:3000,  rate: 0.5},
                    {name: '5000条以上', value:5000, rate: 0.3},
                    {name: '10000条以上', value:10000, rate: 0.25},
                ],
                // PE重包装带
                baozhuangdai: {
                    // 材料性质
                    caiLiaoXingZhi: [
                        {name: '粉粒'},
                        {name: '颗粒'},
                        {name: '固液体'},
                        {name: '其他'}/*
                        {name: '粉粒（涂料、化工原料、化学品）'},
                        {name: '颗粒（橡胶、塑料）'},
                        {name: '固液体（水溶肥、发酵饲料等)'},
                        {name: '其他（木屑、食品、食品添加剂）'}*/
                    ],
                    // 应用领域
                    yingYongLingYu: [
                        {name: '化工'},
                        {name: '塑料'},
                        {name: '建材'},
                        {name: '饲料'},
                        {name: '食品'},
                    ],
                    // 产品重量
                    chanPinZhongLiang: [
                        {name: '特殊密度质量'},
                        {name: '5kg'},
                        {name: '10kg'},
                        {name: '15kg'},
                        {name: '20kg'},
                        {name: '25kg'},
                    ],
                    // 膜面
                    moMian: [
                        {name: '乳白亮光膜', imgType: 3, value: '1'},
                        {name: '乳白哑光膜', imgType: 6, value: '2'},
                        {name: '外白内黑膜', imgType: 6, value: '3', remark: '用于具有避光需求的物料'},
                        {name: '透明膜', imgType: 3, value: '4'},
                        {name: '定制颜色膜', imgType: 3, value: '5'},
                    ],
                    // 额外膜面
                    eWaiMoMian: [
                        {name: '抗静电', eWaiPrice: 200, remark: '一般用于粉粒类物料'},
                        {name: '超强膜', eWaiPrice: 300, remark: '超强膜配方后膜面相对偏硬，厚度可以明显降低'},
                        {name: '防紫外线', eWaiPrice: 400},
                        {name: '其他配方要求'}
                        ],
                    // 透气方式
                    touQiFangShi: [
                        {name: '袋身扎排气孔' },
                        {name: '加装单向排气阀', remark: '加装排气阀膜面厚度至少14丝' },
                        {name: '袋子热封处增加Y型角封'}
                    ],
                    // Y型角封位置
                    YFengKouWeiZhi: [
                        {name: '封底', imgUrl: ''},
                        {name: '封口', imgUrl: ''}
                    ],
                    // 封口增加圆点角封
                    fengKouZengJiaYuanDianJiaoFeng: [
                        {name: '圆点角封合拢（袋口更窄）'},
                        {name: '圆点角封分开（袋口相对宽些）'}
                    ]
                },
                // PE阀口袋
                fakoudai: {
                    // 材料性质
                    caiLiaoXingZhi: [
                      /*  {name: '粉粒（涂料、化工原料、化学品）'},
                        {name: '颗粒（橡胶、塑料）'},
                        {name: '其他（木屑、食品、食品添加剂）'}*/
                        {name: '粉粒'},
                        {name: '颗粒'},
                        {name: '其他'}
                    ],
                    // 应用领域
                    yingYongLingYu: [
                        {name: '化工'},
                        {name: '塑料'},
                        {name: '建材'},
                        {name: '食品'}
                    ],
                    // 产品重量
                    chanPinZhongLiang: [
                        {name: '特殊密度质量'},
                        {name: '5kg'},
                        {name: '10kg'},
                        {name: '15kg'},
                        {name: '20kg'},
                        {name: '25kg'},
                    ],
                    // 膜面
                    moMian: [
                        {name: '乳白亮光膜', imgType: 2, value: '1'},
                        {name: '乳白哑光膜', imgType: 5, value: '2'},
                        {name: '外白内黑膜', imgType: 5, value: '3', remark: '用于具有避光需求的物料'},
                        {name: '透明膜', imgType: 5, value: '4'},
                        {name: '定制颜色膜', imgType: 5, value: '5'},
                    ],
                }

            };
        },
        mounted() {
            if (this.$route.query.packageType) {
                this.baozhuang1.packageType = this.$route.query.packageType;
            }
            if (this.$route.query.areaType) {
                this.baozhuang1.applicationRange = this.$route.query.areaType;
            }
            this.imgUrl = this.defaultImg[this.baozhuang1.packageType];
            if (document.body.clientWidth < 440) {
                this.isModal = false;
            }

        },
        methods: {
            formatTooltip(val) {
                return val + 'cm';
            },
            formatTooltipHou(val) {
                return val + '丝';
            },
            changeTab(item) {
                // this.activeValue = item.value;
            },
            next() {
                if (this.activeValue == 1) {
                    // 校验姓名
                    if (!this.baozhuang1.userName) {
                        this.$message.warning('姓名不能为空');
                        return false;
                    }
                    // 校验手机
                    if (!this.baozhuang1.phone) {
                        this.$message.warning('手机号不能为空');
                        return false;
                    }
                    const myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
                    if (!myreg.test(this.baozhuang1.phone)) {
                        this.$message.warning('手机号格式不对');
                        return false;
                    }
                }
                if (this.activeValue == 2) {
                    if (!this.baozhuang1.prodName) {
                        this.$message.warning({
                            message: "请输入 包装的产品"
                        });
                        return false;
                    }
                    if (!this.baozhuang1.prodCategory) {
                        this.$message.warning({
                            message: "请选择产品物料性质"
                        });
                        return false;
                    }
                }
                console.log(33);
                if (this.activeValue == 3) {
                    if (!this.baozhuang1.surfaceName) {
                        this.$message.warning({
                            message: "请选择 '你需要什么膜面？'"
                        });
                        return false;
                    }
                    this.getPrice();
                }
                this.activeValue = this.activeValue += 1;
            },
            pev() {
                this.activeValue = this.activeValue -= 1;
            },
            xunJia() {
                this.getPrice();
                this.dialogVisible = true;
                const data = JSON.parse(JSON.stringify(this.baozhuang1));
                data.surfaceConfig = data.surfaceConfig.join(',');
                data.fengPosition = data.fengPosition.join(',');
                const url = this.$url.getBackendApiUrl('/api/custom/made/save');
                this.$http.post(url,data).then(res => {
                });
            },
            // 更换左侧大图
            moMianChange(dataList) {
                const match = dataList.filter(item => item.name == this.baozhuang1.surfaceName);
                const myType = [];
                if (match.length) {
                    myType.push(match[0].value);
                    // 是否防滑膜
                    myType.push(this.baozhuang1.striped);
                    if (this.baozhuang1.packageType == 1) {
                        // 是否防抽边(抽边尺寸大于0才视为抽边)
                        const isChouBian = this.baozhuang1.choubian == 1 && this.baozhuang1.choubianSize ? '1' : 0; // '0':不抽边  '1'：抽边
                        myType.push(isChouBian);
                    }
                    this.imgUrl = this.imgUrlObj[this.baozhuang1.packageType][myType.join('-')];
                } else {
                    this.imgUrl = this.defaultImg[this.baozhuang1.packageType];
                }
            },

            getPrice() {
                if (this.baozhuang1.packageType == 1) {
                    this.zhongBaoZhuangDaiPrice();
                } else if (this.baozhuang1.packageType == 2) {
                    this.getPEFaKouDai();
                }
            },
            /**
             * 重包装袋单价
             * 长,宽,厚度(1丝=0.01mm)
             长*宽*2*厚度*0.97/1000=单条袋子克重(g)
             单条袋子克重(g)*[16900（基础价格）+200（若选“抗静电”）+300（若选“超强膜”）+400（若选“防紫外线”）]/1000000=出厂价(元/每条)(保留两位小数，四舍五入）
             出厂价(元/每条)+[0.5（若选“3000条以下”利润）| 0.3（若选“5000条以上”利润）| 0.25（若选“5000条以上”利润）]=重包装袋单价
             * */
            zhongBaoZhuangDaiPrice() {
                // 单条袋子克重(g)：长*宽*2*厚度*0.97/1000=单条袋子克重(g)
                const oneDaiWeight = this.baozhuang1.length * this.baozhuang1.width * 2 * this.baozhuang1.thickness * 0.97 / 1000;

                // 额外价格
                let eWaiPrice = 0;
                this.baozhuang1.surfaceConfig.forEach(item => {
                    const match = this.baozhuangdai.eWaiMoMian.filter(twoItem => twoItem.name == item);
                    if (match.length && match[0].eWaiPrice) {
                        eWaiPrice += match[0].eWaiPrice;
                    }
                });

                // 出厂价：单条袋子克重(g)*[16900（基础价格）+200（若选“抗静电”）+300（若选“超强膜”）+400（若选“防紫外线”）]/1000000=出厂价(元/每条)(保留两位小数，四舍五入）
                const factoryPrice = (oneDaiWeight * (16900 + eWaiPrice ) / 1000000);

                // 重包装袋单价: 出厂价(元/每条)+[0.5（若选“3000条以下”利润）| 0.3（若选“5000条以上”利润）| 0.25（若选“5000条以上”利润）]=重包装袋单价
                const rate = this.shuLiangList.filter(item => item.value == this.baozhuang1.packageNumber)[0].rate;
                this.baozhuang1.unitPrice = (factoryPrice + rate).toFixed(2) ;
                console.log(this.baozhuang1.unitPrice);
            },

            /**
             * PE阀口袋单价
             * 宽*(长+底+3)*厚度*2*0.97/1000+((宽-底)*2+8)*(底-1)*19*0.97/1000=单条袋子克重(g)
             单条袋子克重(g)*【16900（基础价格）+200（若选“抗静电”）+300（若选“超强膜”）+400（若选“防紫外线”）】/1000000=卷材价格(元/每条)(保留两位小数，四舍五入）
             卷材价格(元/每条)++[0.5（若选“3000条以下”利润）| 0.3（若选“5000条以上”利润）| 0.25（若选“5000条以上”利润）] +1（加工费）=重包装袋单价(元/每条)(保留两位小数，四舍五入）
             * */
            getPEFaKouDai() {
                // 单条袋子克重(g) : 宽*(长+底+3)*厚度*2*0.97/1000+((宽-底)*2+8)*(底-1)*19*0.97/1000=单条袋子克重(g)

                const oneDaiWeight = this.baozhuang1.width * (this.baozhuang1.length + this.baozhuang1.bottom + 3) * this.baozhuang1.thickness * 2 * 0.97 / 1000
                    + ((this.baozhuang1.width - this.baozhuang1.bottom) * 2 + 8) * (this.baozhuang1.bottom - 1) * 19 * 0.97/1000;
                // 额外价格
                let eWaiPrice = 0;

                // 无额外膜面
              /*  this.fakoudai.surfaceConfig.forEach(item => {
                    const match = this.baozhuangdai.eWaiMoMian.filter(twoItem => twoItem.name == item);
                    if (match.length && match[0].eWaiPrice) {
                        eWaiPrice += match[0].eWaiPrice;
                    }
                });*/

                // 卷材价格: 单条袋子克重(g)*【16900（基础价格）+200（若选“抗静电”）+300（若选“超强膜”）+400（若选“防紫外线”）】/1000000=卷材价格(元/每条)(保留两位小数，四舍五入）
                const factoryPrice = (oneDaiWeight * (16900 + eWaiPrice ) / 1000000);

                // 包装袋单价: 卷材价格(元/每条)+[0.5（若选“3000条以下”利润）| 0.3（若选“5000条以上”利润）| 0.25（若选“5000条以上”利润）] +1（加工费）=重包装袋单价(元/每条)(保留两位小数，四舍五入）
                const rate = this.shuLiangList.filter(item => item.value == this.baozhuang1.packageNumber)[0].rate;
                this.baozhuang1.unitPrice = (factoryPrice + rate).toFixed(2) ;
                console.log(this.baozhuang1.unitPrice);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .dingzhi-container{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding-bottom: 80px;
        padding-top: 60px;
        .dingzhi-content{
            width: 1200px;
            display: flex;
            margin-bottom: 30px;
            position: relative;

            .dingzhi-left{
                flex: 1;
                height: 750px;
                background: url("../../assets/dingzhi/dingzhi_bg_xiankuang.png") no-repeat center;
                text-align: center;
                display: flex;
                align-items: center;
                margin-top: 10px;
                height: calc(100vh - 200px);
                min-height: 300px;
                div{
                    width: 100%;
                    height: 80%;
                    /*display: flex;*/
                    /*align-items: center;*/
                    /*justify-content: center;*/
                    text-align: center;
                    img{
                        height: 100%;
                    }
                    .baozhuang-title{
                        margin-top: 35px;
                        font-family: PingFangSC-Regular;
                        font-size: 22px;
                        color: rgba(169, 169, 177, 100);
                    }
                }

            }
            .dingzhi-right{
                height: calc(100vh - 200px);
                padding: 0 20px;
                overflow-y: scroll;
                width: 520px;
                .right-title{
                    color: rgba(5, 6, 16, 100);
                    font-size: 35px;
                    margin-top: 40px;
                    margin-bottom: 20px;
                    font-family: PingFangSC-Semibold;
                }
                .p-title{
                    color: rgba(149, 149, 157, 100);
                    font-size: 20px;
                    font-family: PingFangSC-Medium;
                    margin-bottom: 20px;
                }
                .phone-content{
                    display: flex;
                    align-items: center;
                    .phone{
                        color: rgba(57, 63, 227, 100);
                        font-size: 24px;
                        font-family: PingFangSC-Medium;
                        margin-left: 15px;
                    }
                }
                .slid-content{
                    padding-top: 10px;
                    .slid-item{
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        /*margin-top: 20px;*/
                        .left-slid{
                            text-align: left;
                            width: 60px;
                            color: rgba(5, 6, 16, 100);
                            font-size: 24px;
                            font-family: PingFangSC-Medium;
                        }
                        .right-slid{
                            flex: 1;
                        }
                        .show-value{
                            margin-left: 20px;
                        }
                    }
                }
                .input-img{
                    margin-bottom: 15px;
                }
                .wp100{
                    width: 100%;
                }
                .fenge-content{
                    width: 100%;
                    margin-bottom: 30px;
                }
                .mt25{
                    margin-top: 25px;
                }
                .mb10{
                    margin-bottom: 10px;
                }
                .input-item{
                    display: flex;
                    align-items: center;
                    border: 1px solid rgba(233, 233, 238, 1);
                    height: 50px;
                    margin-bottom: 20px;
                    .title{
                        width: 84px;
                        text-align: center;
                        font-size: 18px;
                        font-family: PingFangSC-Medium;
                        color: rgba(149, 149, 157, 100);
                        /*border-right: 1px solid rgba(233, 233, 238, 1);*/
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            right: 0px;
                            top: 18%;
                            width: 1px;
                            background: rgba(233, 233, 238, 1);
                            height: 64%;
                        }
                    }
                    .input{
                        /*height: 50px;*/
                        border: none;
                        /deep/ .el-input__inner{border: none;font-size: 16px}
                    }
                    .danwei{
                        font-size: 16px;
                        font-family: PingFangSC-Medium;
                        color: rgba(149, 149, 157, 100);
                        padding-right: 20px;
                    }
                }
            }
        }
    }
    .header-container{
        display: flex;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;
        height: 65px;
        background: #fff;
    }
    .header-content {
        width: 1200px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .header-left {
            height: 35px;
        }
        .header-left img {
            height: 100%;
        }
        .header-right {
            display: flex;
            font-size: 14px;
            color: #000;
            height: 65px;
            .menu-item {
                padding: 0 6px;
                margin-left: 25px;
                color: #000;
                font-family: PingFangSC-Semibold;
                font-size: 16px;
                line-height: 65px;
                &.active {
                    color: #393fe3;
                    position: relative;
                }
                &.active::before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 15%;
                    width: 70%;
                    height: 2px;
                    background-color: rgba(57, 63, 227, 1);
                    /*background: #393FE3;*/
                }
            }
        }
    }
    .footer-container{
        position: fixed;
        z-index: 999;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 80px;
        background-color: rgba(246, 246, 249, 1);
        box-shadow: 0px -1px 0px 0px rgba(233, 233, 238, 1);
        .footer-content{
            width: 1200px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .right{
                width: 510px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .btn-item{
                    padding: 0 50px;
                    height: 50px;
                    line-height: 50px;
                    border: 1px solid rgba(205, 205, 212, 1);
                    font-size: 22px;
                    font-family: PingFangSC-Semibold;
                    cursor: pointer;
                }
                .next{
                    background-color: rgba(57, 63, 227, 1);
                    box-shadow: 0px 6px 12px 0px rgba(40, 44, 140, 0.2);
                    color: #fff;
                }
                .prv{
                    color: rgba(136, 128, 128, 100);
                    margin-right: 30px;
                }
            }
            .left{
                color: rgba(169, 169, 177, 100);
                font-size: 28px;
                font-family: PingFangSC-Regular;
                .money-text{
                    color: rgba(0, 0, 0, 1);
                    font-size: 32px;
                    font-family: PingFangSC-Semibold;
                }
            }

        }
    }
    .flex1{
        flex: 1;
    }
    .mb20{
        margin-bottom: 20px;
    }
    .orange{
        color: #f98f08b8;
        font-size: 14px;
    }
    .modal-content{
        h1{
            color: rgba(0, 0, 0, 1);
            font-size: 35px;
            font-family: PingFangSC-Semibold;
            text-align: center;
            .unit{
                color: rgba(149, 149, 157, 100);
                font-size: 18px;
                text-align: left;
                font-family: PingFangSC-Medium;
            }
        }

        .title-text{
            color: rgba(149, 149, 157, 100);
            font-size: 16px;
            text-align: center;
            font-family: PingFangSC-Medium;

        }
        .text-content{
            display: flex;
            margin-top: 30px;
            margin-bottom: 15px;
            padding-left: 10px;
        }
        .left-item{
            flex: 1;
            margin-top: 8px;
            .title{
                color: rgba(149, 149, 157, 100);
                font-size: 16px;
                font-family: PingFangSC-Regular;
            }
            .left-item-text{
                display: flex;
                align-items: center;
                margin-top: 10px;
                color: rgba(57, 63, 227, 100);
                font-size: 22px;
                font-family: PingFangSC-Medium;
                margin-bottom: 28px;

                img{
                    width: 27px;
                    margin-right: 10px;
                }
            }
        }
        .right-item{
            width: 170px;
            img{
                width: 160px;
                height: 160px;
            }
            p{
                color: rgba(149, 149, 157, 100);
                font-size: 16px;
                text-align: center;
                font-family: PingFangSC-Regular;
            }
        }
    }
    /deep/ .el-dialog__body{
        padding: 8px 20px 20px;
    }
    /deep/ .el-slider__button-wrapper{
        z-index: 998;
    }
    .getLianxi{
        color: #393fe3;
        padding: 10px 15px;
        border: 1px solid rgba(233, 233, 238, 1);
        font-family: PingFangSC-Medium;
        font-size: 20px;
        cursor: pointer;
    }
</style>